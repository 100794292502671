import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Section from '../../components/Section/Section'

import { Link } from 'gatsby'
import bannerAlternativeStyle from './bannerAlternativeStyle'
import Img from 'gatsby-image'
import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'

const BannerAlternative = props => {
  const { classes, image, title, link, text, buttonTitle } = props
  return (
    <div className={classes.root}>
      {/*<BackgroundImage*/}
      {/*  className={classes.imageStyles}*/}
      {/*  fluid={image.fluid}*/}
      {/*>*/}

      <Section backgroundColor={'rgba(255,255,255,0.9)'} paddingBottom={0} paddingTop={0}>
        <ReactRevealFadeIn>
          <Link className={classes.wrapper} to={'/' + link}>
            <div className={classes.buttonMainWrapper}>
              <div className={classes.buttonWrapper}>
                {/*<p className={classes.P}>*/}
                {/*  {buttonTitle}*/}
                {/*</p>*/}
                {/*<div className={classes.imgWrapper}>*/}
                <Img
                  // className={imgType}
                  style={{ height: '100%', width: '100%' }}

                  fluid={image.fluid}
                  alt={'image.alt'}
                  backgroundColor={'#f2f2f2'}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
                {/*</div>*/}
              </div>
            </div>
            <div className={classes.textWrapper}>
              <h3 className={classes.poppinsHeader}>— {title}</h3>
              <h2 className={classes.H1}>
                {text}
              </h2>
            </div>
          </Link>
        </ReactRevealFadeIn>
      </Section>
      {/*</BackgroundImage>*/}

    </div>

  )
}

BannerAlternative.defaultProp = {
  text: 'Überwintern',

}
//
BannerAlternative.propTypes = {}

export default withStyles(bannerAlternativeStyle)(BannerAlternative)