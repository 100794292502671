import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import mainMenuStyle from './mainMenuStyle.jsx'
import { Link } from 'gatsby'

class MainMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      isOpen: false,
    }

  }


  handleOpen = () => {
    this.setState({ isOpen: true })

  }

  handleClose = () => {
    this.setState({ isOpen: false })

  }

  render() {

    const {
      classes,
      light,

      logoAsH1,
    } = this.props
    const {
      isOpen,
    } = this.state
    const subMenuItemClasses = classNames({
      [classes.menuItem]: true,
      [classes.subMenuItem]: true,
      [classes.menuItemLight]: light,
    })
    const subMenuWrapperClasses = classNames({
      [classes.menuItem]: true,
      [classes.subWrapper]: true,
      [classes.subMenuItem]: true,
      [classes.menuItemLight]: light,

    })
    const aImmitationClasses = classNames({
      [classes.aImmitation]: true,
      [classes.aImmitationIsOpen]: isOpen,
      [classes.aImmitationIsOpenLight]: light & isOpen,
      [classes.aImmitationLight]: light,
      [classes.aImmitationIsClosed]: !isOpen,
    })

    // const ulClasses = classNames({
    //   [classes.menuItem]: true,
    //   [classes.submenuWrapper]: true,
    //   [classes.menuItemLight]: light,
    // })
    const liClasses = classNames({
      [classes.menuItem]: true,
      [classes.menuItemLight]: light,
    })
    return (
      <nav className={classes.root}>
        <div style={{ height: 100 }}>
          <ul className={classes.mainMenuWrapper}>
            <li className={liClasses}>
              <Link to="/">
                Home
              </Link>
            </li>
            <li
              onMouseEnter={this.handleOpen} onMouseLeave={this.handleClose}
              onClick={this.handleOpen}
              tabIndex="0"
              onBlur={this.handleClose}
              className={liClasses}
            >
              <div className={aImmitationClasses}> Ferienhäuser</div>
              <ul

                className={subMenuWrapperClasses}
                style={this.state.isOpen ? { padding: 0, paddingTop: 10, paddingLeft: 0, opacity: 1 } : {
                  opacity: 0,

                  padding: 0,
                  paddingTop: 10,
                  paddingLeft: 0,
                }}>
                <li className={classNames(subMenuItemClasses)}>
                  <Link to="/meine-kleine-finca">
                    Meine Kleine Finca
                  </Link>
                </li>
                <li className={classNames(subMenuItemClasses)}>
                  <Link to="/casa-novo-sancti-petri">
                    Casa Novo Sancti Petri
                  </Link>
                </li>
                <li className={classNames(subMenuItemClasses)}>
                  <Link to="/casa-playa-conil">
                  Casa Playa Conil
                  </Link>
                </li>

              </ul>
            </li>
            <li className={liClasses}>
              <Link to="/ueberwintern">
                Überwintern
              </Link>
            </li>
            <li className={liClasses}>
              <Link to="/blog">
                Blog
              </Link>
            </li>


          </ul>
        </div>


        <div className={classes.logoWrapper}>
          <Link to={'/'}>
            {logoAsH1 ?
              (<h1 className={classes.logo}>
                Costa Cadiz
              </h1>) :
              (<p className={classes.logo} style={light ? { color: '#fff' } : { color: '#1a1a1a' }}>
                Costa Cadiz
              </p>)}
          </Link>
        </div>


      </nav>


    )
  }
}

MainMenu.defaultProp = {
  logoAsH1: false,
  light: false,
}

MainMenu.propTypes = {
  logoAsH1: PropTypes.bool,
  light: PropTypes.bool,
}

export default withStyles(mainMenuStyle)(MainMenu)
