import { H2, poppinsHeader } from 'assets/jss/material-kit-react.jsx'


const sectionHeaderStyle = {
  root: {
    width: '100%',
    textAlign: 'center',
    padding: '0 0 40px',
  },
  poppinsHeader: {
 ...poppinsHeader
  },
  H2: {
    ...H2,
    marginBottom: 25,
    paddingBottom: 3,
  },
}
export default sectionHeaderStyle