import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import instagramSectionStyle from './instagramSectionStyle.jsx'
import classNames from 'classnames'

import { graphql, useStaticQuery } from 'gatsby'
import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../Section/Section'
import SectionHeader from '../SectionHeader/SectionHeader'
import InstagramImage from './InstagramImage'


const InstagramSection = props => {


  const data = useStaticQuery(graphql`
    query{
      instaNode{username}
      allInstaNode(filter:{mediaType:{eq:"GraphImage"}} limit:4){
        edges{
          node{
            id
            caption
            preview
          }
        }
      }
    }
  `)

  const { classes } = props

  return (
    <Section backgroundColor={'#e3eff0'} paddingBottom={100}>
        <SectionHeader
          header={'Instagram'}
          insta
          instalink={`https://www.instagram.com/${data.instaNode.username}`}
          subHeader={`@${data.instaNode.username}`}
          subHeaderSize={36}
        />
        <div className={classes.wrapper}>
          <div className={classNames(classes.subWrapper, classes.first)}>

            {data.allInstaNode.edges.slice(0, 2).map(insta => {
              return (
               <InstagramImage insta={insta}/>
              )
            })}
          </div>

          <div className={classNames(classes.subWrapper, classes.second)}>

            {data.allInstaNode.edges.slice(2, 4).map(insta => {
              return (
                <InstagramImage insta={insta}/>
              )
            })}
          </div>
        </div>




    </Section>
  )

}

export default withStyles(instagramSectionStyle)(InstagramSection)
