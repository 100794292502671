import React from 'react'
// @material-ui/core components


import Fade from 'react-reveal/Fade'

function ReactRevealFadeInWhen({ ...props }) {
  return (
    <Fade clear duration={1000} mountOnEnter={true}>
      {props.children}
    </Fade>
  )
}



export default ReactRevealFadeInWhen
