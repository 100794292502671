import { cardTitle, H3, imageDarkenOnHover, P, title } from 'assets/jss/material-kit-react.jsx'
import imagesStyle from 'assets/jss/material-kit-react/imagesStyles.jsx'

const ferienhaeuserLinksSectionStyle = {
  imageDarkenOnHover,

  sectionTitleWrapper: {},
  sectionTitle: {
    fontFamily: '\'Poppins\', sans-serif',
    fontSize: 12,
    textTransform: 'uppercase',
    lineHeight: 1.0,
    letterSpacing: '3px',
    textAlign: 'center',
    fontWeight: 500,
    color: '#1a1a1a',
    padding: '0 0 40px',
  },

  imgWrapper: {
    margin: '0 0 30px',
  },

  imgClass: {
    width: '100%',
    minHeight: 470,
    height:470,
    maxHeight:470,

  },

  itemWrapper: {

    '&:nth-of-type(even)': {
      marginRight: 50,
      marginLeft: 50,

    },
    width: '100%',
    maxWidth: 370,
    paddingBottom: 80,
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      marginRight: 0,
      float: 'none',
    },
  },
  wrapper: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 992px)': {
      flexDirection: 'column',
      alignItems: 'center',

    },
    '@media (max-width: 768px)': {

      marginRight: 0,
    },


  },

  mainLinkWrapper: {
    display: 'flex',
    paddingInlineStart: 0,
    flexDirection: 'row',
    '&  li:nth-child(3n)': {

      marginRight: 0,
      clear: 'right',

    },
    '&  li:nth-child(3n+1)': {


      clear: 'left',

    },
    // '&  ul': {
    //   paddingInlineStart: 0,
    //
    //
    // },

  },
  H3: {
    ...H3,
    fontSize: 36,
    textAlign: 'center',
  },
  P: {
    ...P,
    margin: '20px 0 0 0',
    lineHeight: 1.45,
    color: '#1a1a1a',
  },

  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  ...imagesStyle,

  cardTitle,
  smallTitle: {
    color: '#6c757d',
  },
  description: {
    color: '#999',
    minHeight: '130px',
    maxHeight: '130px',
  },
  justifyCenter: {
    justifyContent: 'center !important',
  },
  socials: {
    marginTop: '0',
    width: '100%',
    transform: 'none',
    left: '0',
    top: '0',
    height: '100%',
    lineHeight: '41px',
    fontSize: '20px',
    color: '#999',
  },
  margin: {
    margin: '5px',
  },
}

export default ferienhaeuserLinksSectionStyle
