import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import { Link } from 'gatsby'
import burgerMenuStyle from './burgerMenuStyle'

import { Dialog } from '@material-ui/core'

class BurgerMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      setOpen: false,
    }

  }


  handleOpen = () => {
    this.setState({
      setOpen: true,
    })
  }

  handleClose = () => {
    this.setState({
      setOpen: false,
    })
  }

  render() {

    const {
      classes,
      light,
    } = this.props
    const {
      setOpen,
    } = this.state

    return (

      <div className={classes.root}>
        <button onClick={this.handleOpen}
                style={{
                  fontSize: '100%',
                  fontFamily: 'inherit',
                  border: 0,
                  padding: 0,
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                }}
                className={classes.wrapper}
        >
          <div className={classes.bar} style={light ? { backgroundColor: '#fff' } : { backgroundColor: '#1a1a1a' }}/>
          <div className={classes.bar} style={light ? { backgroundColor: '#fff' } : { backgroundColor: '#1a1a1a' }}/>
          <div className={classes.bar} style={light ? { backgroundColor: '#fff' } : { backgroundColor: '#1a1a1a' }}/>

        </button>
        <Dialog
          open={setOpen}
          onClose={this.handleClose}
          className={classes.modal}
          fullScreen
          fullWidth
          transitionDuration={{ enter: 300, exit: 500 }}
        >
          <div className={classes.modalWrapper}>

            <div className={classes.xWrapper}>
              <button onClick={this.handleClose}
                      style={{ fontSize: '100%', fontFamily: 'inherit', border: 0, cursor: 'pointer' }}
                      className={classes.closeX}
              >
                <div className={classes.closeBar} style={{ transform: 'rotate(45deg)' }}/>
                <div className={classes.closeBar} style={{ transform: 'rotate(-45deg)' }}/>

              </button>
            </div>


            <nav>


              <ul className={classes.mainMenuWrapper}>
                <li className={classes.menuItem}>
                  <Link to="/">
                    Home
                  </Link>
                </li>
                <li className={classes.menuItem}>
                  <Link to="/meine-kleine-finca">
                    Ferienhäuser
                  </Link>
                  <div style={{ height: 20 }}/>
                  <ul className={classes.subMenuWrapper}>
                    <li className={classNames(classes.menuItem, classes.subItem)}>
                      <Link to="/meine-kleine-finca">
                        Meine Kleine Finca
                      </Link>
                    </li>
                    <div className={classes.divider}>/</div>
                    <li className={classNames(classes.menuItem, classes.subItem)}>
                      <Link to="/casa-novo-sancti-petri">
                        Casa Novo Sancti Petri
                      </Link>
                    </li>
                    <div className={classes.divider}>/</div>
                    <li className={classNames(classes.menuItem, classes.subItem)}>
                      <Link to="/casa-playa-conil">
                        Casa Playa Conil
                      </Link>
                    </li>

                  </ul>
                </li>


                <li className={classes.menuItem}>
                  <Link to="/ueberwintern">
                    Überwintern
                  </Link>
                </li>
                <li className={classes.menuItem}>
                  <Link to="/blog">
                    Blog
                  </Link>
                </li>

                <ul className={classes.bottomMenuWrapper}>

                  <li className={classNames(classes.bottomItem)}>
                    <a href="mailto:info@costacadiz.de">
                      Kontakt
                    </a>
                  </li>
                  <div className={classes.bottomMenudivider}>・</div>
                  <li className={classNames(classes.bottomItem)}>
                    <Link to="/ueber-mich">
                      Über Mich
                    </Link>
                  </li>
                  <div className={classes.bottomMenudivider}>・</div>
                  <li className={classNames(classes.bottomItem)}>
                    <Link to="/impressum">
                      Impressum
                    </Link>
                  </li>


                </ul>
              </ul>


            </nav>
          </div>
        </Dialog>
      </div>


    )
  }
}

BurgerMenu.defaultProp = {
  light: false,
}

BurgerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  light: PropTypes.bool,

}

export default withStyles(burgerMenuStyle)(BurgerMenu)
