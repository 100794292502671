import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import buttonOutlinedStyle from './buttonOutlinedStyle'


const ButtonOutlined = props => {

  const { classes } = props

  return (
    <a
      href={props.link}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      <div className={classes.root}>
        {props.title}
      </div>
    </a>


  )

}

export default withStyles(buttonOutlinedStyle)(ButtonOutlined)
