import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// @material-ui/icons
import instagramSectionStyle from './instagramSectionStyle.jsx'
import classNames from 'classnames'

import { graphql, useStaticQuery } from 'gatsby'
import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../Section/Section'
import SectionHeader from '../SectionHeader/SectionHeader'


const InstagramImage = props => {

  const { classes,insta } = props


  return (
    <ReactRevealFadeIn>
      <div className={classes.articleWrapper}>
        <div className={classes.linkClass}>
          <a href={'https://www.instagram.com/p/' + insta.node.id} rel={"noopener noreferrer"}  target={"_blank"}>
            <img
              className={classNames(classes.imgStyle, classes.imageDarkenOnHover)}
              src={insta.node.preview}
              alt={insta.node.caption}

            />
          </a>

        </div>
      </div>
    </ReactRevealFadeIn>
  )

}

export default withStyles(instagramSectionStyle)(InstagramImage)
