import { container, P, poppinsHeader } from 'assets/jss/material-kit-react.jsx'


const footerStyle = theme => ({
  root: {
    width: '100%',
  },
  poppinsHeader: {
    ...poppinsHeader,
    textAlign: 'left',
    padding: '0 0 20px',
    '@media (max-width: 768px)': {
      textAlign: 'center',

    },
  },
  P: {
    ...P,
    lineHeight: 1.45,

    fontWeight: 500,
    '& a': {
      ...P,
      lineHeight: 1.45,
      fontWeight: 500,
    },
  },
  menuWrapper: {
    padding: '0 0 80px',

    '& a': {

      textDecoration: 'none',

      borderBottom: '1px solid transparent',
      transition: 'border-bottom 0.4s ease',
      '&:hover': {
        borderBottom: '1px solid #1a1a1a',
      },

    },
    '& ul': {
      boxSizing: 'border-box',
      borderImageWidth: 1,
    },
    '& li': {
      textAlign: 'left',
      boxSizing: 'border-box',
      borderImageWidth: 1,
    },
    '@media (max-width: 768px)': {
      '& li': {
        textAlign: 'center',

      },
    },
  },
  navWrapper: {
    marginRight: '4.1%',
    width: '20%',
    '@media (max-width: 768px)': {
      margin: 0,
      width: '100%',
    },
  },

  copyright: {

    textAlign: 'center',

  },
  creditWrapper: {
    justify: 'center',
    alignItems: 'center',
    paddingBottom: 40,
  },
  credit: {
    lineHeight: 1.7,
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    color: '#1a1a1a',
    fontSize: 18,
    padding: 0,
    margin: 0,
  },
  container: {
    ...container,
    padding: 0,
    backgroundColor: '#e3eff',


  },
  footerMenusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  block: {
    paddingTop: '0.9375rem',
    paddingBottom: '0.9375rem',

    alignText: 'left',
    textTransform: 'uppercase',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
  },


  footerWhiteFont: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
    },
  },

  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
  icon: {
    width: '18px',
    height: '18px',
    position: 'relative',
    top: '3px',
  },
  cookieC: {
    backgroundColor: 'khaki',
  },
  Croot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  Cwrapper: {
    width: 300,
    maxWidth: 300,
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"Cormorant Garamond", sans-serif',
    color: '#1a1a1a',
    padding: 15,
    lineHeight: 1.45,
    fontSize: 22,
    paddingLeft: 40,
    paddingRight: 40,
    minWidth: 170,
    backgroundColor: 'transparent',
    border: '1px solid #1a1a1a',
    transition: 'all 0.5s ease',
    '&:hover': {
      backgroundColor: '#1a1a1a',
      color: 'white',
    },

  },
  cfont: {
    fontSize: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"Cormorant Garamond", sans-serif',
    color: '#1a1a1a',
    textAlign: 'center',
  },
})
export default footerStyle
