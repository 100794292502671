/*eslint-disable*/
import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// nodejs library that concatenates classes
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import footerStyle from './footerStyle.jsx'
// @material-ui/icons
import { Link, navigate } from 'gatsby'
import Section from '../Section/Section'
import CookieConsent from 'react-cookie-consent'

const Footer = (props) => {
  const { classes } = props
  const footerClasses = classNames({
    [classes.footer]: true,
    // [classes.footerWhiteFont]: whiteFont,
  })

  return (
    <footer className={classes.root}>
      <Section backgroundColor={'#e3eff0'} paddingBottom={0}
               innerStyles={{
                 maxWidth: 1210,

               }}
      >
        <div className={classes.footerMenusWrapper}>
          <nav role="navigation"
               className={classNames(classes.navWrapper)}>
            <h3 className={classes.poppinsHeader}>
              Hauptmenü</h3>
            <ul className={classes.menuWrapper}>
              <li>
                <Link to="/" className={classes.P}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/meine-kleine-finca" className={classes.P}>
                  Meine Kleine Finca
                </Link>
              </li>
              <li>
                <Link to="/casa-novo-sancti-petri" className={classes.P}>
                 Casa Novo Sancti Petri
                </Link>
              </li>
              <li>
                <Link to="/casa-playa-conil" className={classes.P}>
                  Casa Playa Conil
                </Link>
              </li>
              <li>
                <Link to="/ueberwintern" className={classes.P}>
                  Überwintern
                </Link>
              </li>
              <li>
                <Link to="/blog" className={classes.P}>
                  Blog
                </Link>
              </li>
            </ul>
          </nav>

          <nav role="navigation"
               className={classNames(classes.centerOnBreak)}>
            <h3 className={classes.poppinsHeader}>Wichtige Links</h3>
            <ul className={classes.menuWrapper}>
              <li>
                <Link to="/ueber-mich" className={classes.P}>
                  Über mich
                </Link>
              </li>
              <li className={classes.P}>
                <a href="mailto:info@costacadiz.de">
                  Kontakt
                </a>
              </li>
              <li>
                <Link to="/impressum" className={classes.P}>
                  Impressum
                </Link>
              </li>
              <li>
                <Link to="/datenschutzerklaerung" className={classes.P}>
                  Datenschutzerklärung
                </Link>
              </li>

            </ul>
          </nav>
        </div>
        <div className={classes.creditWrapper}>
          <ul className={classes.credit} role="contentinfo">
            <li className={classes.copyright}>Copyright © 2019 Costa Cadiz</li>
          </ul>


        </div>

      </Section>
      <CookieConsent
        enableDeclineButton
        flipButtons
        hideOnAccept={true}
        location={'bottom'}
        style={{ width: 350, border: '1px solid #1a1a1a', margin: 15, color: '#1a1a1a', backgroundColor: 'white' }}
        buttonText={'Ok'}
        declineButtonText={'Mehr Infos'}

        onDecline={() => navigate('/datenschutzerklaerung')}

        declineButtonStyle={{
          width: '90%',
          marginTop: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: '"Cormorant Garamond", sans-serif',
          color: '#1a1a1a',

          lineHeight: 1.45,
          fontSize: 18,
          textDecoration: 'underline',

          backgroundColor: 'transparent',


        }}
        buttonStyle={{

          width: '90%',
          marginTop: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: '"Cormorant Garamond", sans-serif',
          color: '#1a1a1a',
          padding: 10,
          lineHeight: 1.45,
          fontSize: 22,
          paddingLeft: 40,
          paddingRight: 40,

          backgroundColor: 'transparent',
          border: '1px solid #1a1a1a',
          transition: 'all 0.5s ease',

        }}

      >
        <span className={classes.cfont}>
          Nach EU-Richtlinie weisen wir hiermit auf die Nutzung von Cookies hin
        </span>


      </CookieConsent>

    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool,
}

export default withStyles(footerStyle)(Footer)
