import { H2, P } from 'assets/jss/material-kit-react.jsx'


const spainHolidaysCtaStyle = {
  P: {
    ...P,
    lineHeight: 1.45,
    color:"#2b2b2b"
  },
  H2: {
    ...H2,
    marginBottom: 25,
    paddingBottom: 3,
    color:"#2b2b2b"
  },
}
export default spainHolidaysCtaStyle