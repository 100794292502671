import { title } from 'assets/jss/material-kit-react.jsx'


const impressionenWithVideoStyle = {
  itemGrid: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  videoWrapper:{
    width: '100%',
    marginBottom: 50,
    height: 500,

    '@media (max-width: 768px)': {
      height:350,
      marginBottom: 34,
    },
  },
  itemWrapper: {
    flex: 1,
    '&:nth-of-type(odd)': {
      marginRight: 50,
      '@media (max-width: 992px)': {

        marginRight: 0,


      },
    },
    height: 440,
    width: 580,
    maxWidth: 580,
    maxHeight: 400,
    overflow: 'hidden',
    paddingBottom: 50,
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      width: '100%',
      float: 'none',
      margin: 0,
      paddingBottom: 34,


    },
    '@media (max-width: 992px)': {

      marginRight: 0,


    },

  },
  imageClasses: {
    height: '100%', width: '100%',


  },
  wrapper: {

    display: 'flex',
    flex: 1,
    maxWidth: '100%',
    '@media (max-width: 992px)': {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginRight: 0,
    },


  },
  sectionTitleWrapper: {

    textAlign: 'center',
    fontWeight: 500,
    color: '#1a1a1a',
    padding: '0 0 40px',
  },
  sectionTitleTop: {
    fontFamily: '\'Poppins\', sans-serif',
    fontSize: 12,
    textTransform: 'uppercase',
    lineHeight: 1.0,
    letterSpacing: '3px',
  },
  sectionTitleBottom: {
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    fontSize: 48,

    lineHeight: 1.06,
    letterSpacing: '3px',
  },

  header: {
    margin: 0,
    paddingBottom: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 1.06,
    letterSpacing: '-1px',
  },


  textCenter: {
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  description: {
    color: '#999',
  },
  galleryItem: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  paper: {

    textAlign: 'center',
    color: '#000',
    backgroundColor: 'khaki',
    height: 140,
    width: 100,
  },
}

export default impressionenWithVideoStyle
