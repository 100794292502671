import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import Img from 'gatsby-image'

import { Link } from 'gatsby'

import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../../components/Section/Section'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import ferienhaeuserLinksSectionStyle from './ferienhaeuserLinksSectionStyle'

const FerienhaeuserLinksSection = (props) => {
  const { classes, seitenlinks } = props

  return (
    <Section backgroundColor={'#f2f2ee'} paddingBottom={40} paddingTop={160}>

      <SectionHeader header={'Ferienhäuser'}/>


      <div className={classes.wrapper}>

        {seitenlinks.map(seitenlinkElement => {
          return (
            <ReactRevealFadeIn>
              <div className={classes.itemWrapper}>

                <Link to={'/' + seitenlinkElement.link.slug}>
                  <div className={classes.imgWrapper}>
                    <Img
                      className={classNames(classes.imgClass, classes.imageDarkenOnHover)}
                      alt={seitenlinkElement.bild.alt}
                      fluid={seitenlinkElement.bild.fluid}
                      backgroundColor={'#f2f2f2'}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </Link>
                <Link to={'/' + seitenlinkElement.link.slug}>
                  <div className={classes.itemGrid}>
                    <h3 className={classes.H3}>
                      {seitenlinkElement.titel}
                      {/*{seitenlinkElement.titel.slice(0, seitenlinkElement.titel.search(' '))} <br/>*/}
                      {/*{seitenlinkElement.titel.slice(seitenlinkElement.titel.search(' '), seitenlinkElement.titel.length)}*/}
                    </h3>

                    <p className={classes.P}>
                      {seitenlinkElement.untertitel}
                    </p>

                  </div>
                </Link>
              </div>
            </ReactRevealFadeIn>

          )
        })}


      </div>


    </Section>
  )


}


export default withStyles(ferienhaeuserLinksSectionStyle)(FerienhaeuserLinksSection)
