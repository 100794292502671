const buttonOutlinedStyle = {
  root: {

    marginTop: 30,
    fontFamily: '"Cormorant Garamond", sans-serif',
    color: '#1a1a1a',
    padding: 15,
    lineHeight: 1.45,
    fontSize: 22,
    paddingLeft: 40,
    paddingRight: 40,
    minWidth: 170,
    backgroundColor: 'transparent',
    border: '1px solid #1a1a1a',
    transition: 'all 0.5s ease',
    '&:hover': {
      backgroundColor: '#1a1a1a',
      color: 'white',
    },

  },
}

export default buttonOutlinedStyle
