import { poppinsHeader } from 'assets/jss/material-kit-react.jsx'

const burgerMenuStyle = theme => ({
  root: {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0, right: 0,
    padding: '36px',
    display: 'flex',
    paddingRight: '60px',
    justifyContent: 'flex-end',
    alignContent: 'center',
    zIndex: 1000,

  },
  wrapper: {

    height: 25,
    width: 25,


    transition: 'all 0.5s ease',
    '&:hover': {
      transform: 'scale(1.3)',

    },
  },
  closeBar: {
    height: 1,
    width: 25,
    marginLeft: '-50%',
    // marginBottom:'-50%',
    backgroundColor: '#1a1a1a',
  },
  closeX: {

    height: 25,
    width: 25,
    backgroundColor: 'transparent',
    transition: 'all 0.5s ease',
    '&:hover': {
      transform: 'scale(1.3)',

    },

  },
  xWrapper: {
    display: 'flex',


    position: 'absolute',
    top: 0, right: 0,
    padding: '36px',
    alignContent: 'center',
    justifyContent: 'center',
    paddingRight: '60px',

  },
  bar: {
    height: 1,
    width: 25,
    backgroundColor: '#fff',
    margin: '7px 0px',
  },

  modal: {
    backgroundColor: 'transparent',


  },
  modalWrapper: {
    display: 'flex',
    flex: 1,
    height: '100%',


    width: '100%',
    minHeight: 600,
    backgroundColor: '#F2F2EE',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  menuItem: {

    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    color: '#1a1a1a',

    fontWeight: 500,
    lineHeight: 1.12,
    '& a': {
      fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
      color: '#1a1a1a',
      fontSize: '64px',
      textDecoration: 'none',
      opacity: 1,

      transition: 'opacity 0.4s ease',
      '&:hover': {
        opacity: 0.5,
      },
      '@media (max-width: 768px)': {
        fontSize: 36,
      },
    },

    paddingBottom: '20px',
    boxSizing: 'border-box',
    borderImageWidth: 1,
    '@media (max-width: 768px)': {
      fontSize: 36,
    },
  },
  subItem: {
    lineHeight: 2,
    paddingRight: 10,
    fontWeight: 300,
    '& a': {
      fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
      color: '#1a1a1a',
      fontSize: '32px',
      textDecoration: 'none',
      transition: 'opacity 0.4s ease',
      opacity: 1,
      '&:hover': {
        opacity: 0.5,
      },
      '& li': {
        boxSizing: 'border-box',
        borderImageWidth: 1,
        '@media (max-width: 768px)': {
          padding: 0,
        },
      },
      '@media (max-width: 768px)': {
        fontSize: 25,
        padding: 0,
      },
    },
    '@media (max-width: 768px)': {
      fontSize: 25,
      padding: 0,
    },
    paddingBottom: 0,
  },

  subMenuWrapper: {
    width: '100%',
    listStyle: 'none',
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    textAlign: 'center',
    padding: 0,
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  divider: {
    paddingRight: 10,
    color: '#1a1a1a',
    fontSize: '32px',
    lineHeight: 2,
    fontWeight: 300,
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  bottomMenuWrapper: {
    paddingTop: 50,
    width: '100%',
    listStyle: 'none',
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    textAlign: 'center',
    padding: 0,
    color: '#1a1a1a',
    '@media (max-width: 768px)': {
      // flexDirection: 'column',
    },
  },
  bottomItem: {
    ...poppinsHeader,
    letterSpacing: 3,
    fontSize: 12,
    color: '#1a1a1a',
    '& a': {

      color: '#1a1a1a',

      textDecoration: 'none',
      transition: 'opacity 0.4s ease',
      opacity: 1,
      '&:hover': {
        opacity: 0.5,
      },
      '& li': {
        boxSizing: 'border-box',
        borderImageWidth: 1,
        '@media (max-width: 768px)': {
          padding: 0,
        },
      },
      '@media (max-width: 768px)': {

        padding: 0,
      },
    },
  },
  bottomMenudivider: {
    ...poppinsHeader,
    letterSpacing: 3,
    fontSize: 22,
    fontWeight: 'bold',
    '@media (max-width: 768px)': {
      // display: 'none',
    },
  },

  mainMenuWrapper: {
    padding: 0,
    paddingTop: 36,
    paddingBottom: 36,
    margin: 0,
    listStyle: 'none',
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    textAlign: 'center',


  },
})

export default burgerMenuStyle
