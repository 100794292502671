import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Img from 'gatsby-image'

import impressionenWithVideoStyle from './impressionenWithVideoStyle.jsx'

import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../../components/Section/Section'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import PropTypes from 'prop-types'
import ReactRevealFadeInWhen from '../../components/ReactRevealFadeInWhen/ReactRevealFadeInWhen'


const ImpressionenWithVideo = props => {

  const { classes, link,videoAutoplay } = props

  let videoURL = 'https://www.youtube.com/embed/'+ link.slice(link.search('v=') + 2, link.length) +'?rel=0&amp;autoplay=0&iv_load_policy=3&playlist=' + link.slice(link.search('v=') + 2, link.length)

  if(videoAutoplay){
    videoURL = 'https://www.youtube.com/embed/'+ link.slice(link.search('v=') + 2, link.length) + '?rel=0&amp;autoplay=1&mute=1&loop=1&iv_load_policy=3&playlist=' + link.slice(link.search('v=') + 2, link.length)
  }

  return (

    <Section paddingBottom={40} backgroundColor={props.backgroundColor ? props.backgroundColor : '#f4f4f4'}>
      <SectionHeader header={props.title}
                     subHeader={props.subtitle}/>

      <ReactRevealFadeIn>
        <div className={classes.videoWrapper}>

          <iframe width="100%"
                  height='100%'
                  src={videoURL}
                  className={classes.youtube}
                  frameBorder="0"
                  allowFullScreen
          >
          </iframe>
        </div>

        <div className={classes.wrapper}>

          {props.images.slice(0, 2).map(image => {
            return (
              <div className={classes.itemWrapper}>


                <Img
                  // className={imageClasses}
                  style={{ height: '100%', width: '100%' }}
                  fluid={image.fluid}
                  alt={image.alt}
                  backgroundColor={'#f2f2f2'}
                  objectFit="contain"
                  objectPosition="50% 50%"
                />


              </div>
            )
          })}

        </div>
      </ReactRevealFadeIn>
      <ReactRevealFadeIn>
        <div className={classes.wrapper}>
          {props.images.slice(2, 4).map(image => {
            return (
              <div className={classes.itemWrapper}>


                <Img
                  className={classes.imageClasses}
                  fluid={image.fluid}
                  alt={image.alt}
                  backgroundColor={'#f2f2f2'}
                  objectFit="contain"
                  objectPosition="50% 50%"
                />


              </div>
            )
          })}

        </div>
      </ReactRevealFadeIn>
    </Section>
  )

}
ImpressionenWithVideo.defaultProp = {
  backgroundColor: '#f4f4f4',
}

ImpressionenWithVideo.propTypes = {
  backgroundColor: PropTypes.string,
}
export default withStyles(impressionenWithVideoStyle)(ImpressionenWithVideo)
