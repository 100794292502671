import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Section from '../../components/Section/Section'

import ButtonOutlined from '../../components/ButtonOutlined/ButtonOutlined'
import spainHolidaysCtaStyle from './spainHolidaysCtaStyle'


const SpainHolidaysCta = props => {
  const { classes, title, subtitle, buttonTitle, buttonLink } = props


  return (
    <div>
      <Section backgroundColor={'#fff'} innerStyles={{ maxWidth: 705 }}>
        <h2 className={classes.H2}>
          {title}
        </h2>
        <p className={classes.P}>
          {subtitle}
        </p>
        <ButtonOutlined
          title={buttonTitle}
          link={buttonLink}
        />
      </Section>
    </div>

  )
}


export default withStyles(spainHolidaysCtaStyle)(SpainHolidaysCta)