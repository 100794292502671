import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import headerStyle from './headerStyle.jsx'

import MainMenu from './MainMenu/MainMenu'
import BurgerMenu from './BurgerMenu/BurgerMenu'
import FixedMenu from './FixedMenu/FixedMenu'

import Helmet from 'react-helmet'
import SEO from '../SEO'


class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      isOpen: false,
    }

  }


  render() {

    const {
      classes,
      light,
      logoAsH1,
      absolute,

      SEOtitle,
      SEOdescription,
      SEOimage,
      SEOpathname,
      SEOarticle,
    } = this.props


    return (

      <header className={classes.root} style={absolute ? { position: 'absolute' } : { backgroundColor: 'white' }}>
        <SEO
          title={SEOtitle}
          description={SEOdescription}
          image={SEOimage}
          pathname={SEOpathname}
          article={SEOarticle}
        />

        <Helmet>
          <script src="//code.tidio.co/4yhot3po6qpyc90pvyomygwkxabco9om.js"/>
        </Helmet>
        <FixedMenu/>
        <div className={classes.siteHeaderContent}>
          <MainMenu logoAsH1={logoAsH1} light={!!light}/>
          <BurgerMenu light={!!light}/>
        </div>

      </header>


    )
  }
}

Header.defaultProp = {
  absolute: false,
  light: false,
  logoAsH1: false,

}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  light: PropTypes.bool,
  logoAsH1: PropTypes.bool,
}

export default withStyles(headerStyle)(Header)
