import { imageDarkenOnHover, title } from 'assets/jss/material-kit-react.jsx'


const instagramSectionStyle = {
  imageDarkenOnHover,
  itemGrid: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  wrapper: {

    display: 'flex',
    width: '100%',

    flexDirection: 'row',

    maxWidth: '100%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: -25,
      marginLeft: -25,

    },
  },
  subWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  first: {
    marginLeft: -25,
    '@media (max-width: 768px)': {
      margin: 0,
    },
  },
  second: {
    marginRight: -25,
    '@media (max-width: 768px)': {
      margin: 0,
    },
  },
  articleWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 265,
    marginRight: 25,
    marginLeft: 25,
    paddingBottom: 34,
    '@media (max-width: 768px)': {
      margin: 0,
      maxWidth: '100%',
      '&:nth-of-type(odd)': {
        marginRight: 34,
      },
      paddingBottom: 34,
    },
    '@media (max-width: 576px)': {
      '&:nth-of-type(odd)': {
        marginRight: 20,
      },
      paddingBottom: 20,
    },
  },
  linkClass: {
    flex: 1,
    // height: 265,
    // maxHeight: 265,
    // minHeight: 265,
    //
    // '@media (max-width: 768px)': {
    //   height: 265,
    //   maxHeight: 265,
    //   minHeight: 265,
    // },
    // '@media (max-width: 576px)': {
    //   height: 230,
    //   maxHeight: 230,
    //   minHeight: 230,
    //
    // },
    overflow: 'hidden',
  },
  itemWrapper: {
    flex: 1,
    marginRight: 50,
    height: 440,
    width: 580,
    maxWidth: 580,
    maxHeight: 400,
    overflow: 'hidden',
    paddingBottom: 50,
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      width: '100%',
      float: 'none',
      margin: 0,
      paddingBottom: 34,
    },
  },
  imgStyle: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: '50% 50%',
  },

  sectionTitleWrapper: {

    textAlign: 'center',
    padding: '0 0 40px',
  },
  sectionTitleTop: {
    fontFamily: '\'Poppins\', sans-serif',
    fontSize: 12,
    textTransform: 'uppercase',
    lineHeight: 1.0,
    letterSpacing: '3px',
  },
  sectionTitleBottom: {
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    fontSize: 36,
    lineHeight: 1.06,
    paddingBottom: 3,
  },

  header: {
    margin: 0,
    paddingBottom: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 1.06,
    letterSpacing: '-1px',
  },


  textCenter: {
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  description: {
    color: '#999',
  },
  galleryItem: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  paper: {

    textAlign: 'center',
    color: '#000',
    backgroundColor: 'khaki',
    height: 140,
    width: 100,
  },
}

export default instagramSectionStyle
