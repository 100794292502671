import React from 'react'
// nodejs library that concatenates classes
// nodejs library to set properties for components
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import pageHeaderFullWithSliderStyle from './pageHeaderFullWithSliderStyle'
import Img from 'gatsby-image'
import ButtonScrollDown from '../ButtonScrollDown/ButtonScrollDown'
import Section from '../Section/Section'
import WeatherWidget from '../WeatherWidget/WeatherWidget'
import { graphql, useStaticQuery } from 'gatsby'
import Slider from 'react-slick/lib'

const PageHeaderFullWithSlider = props => {

  const data = useStaticQuery(graphql`
      query {
          datoCmsHome{
              wetterWidgetLink
          }
      }
  `)
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1500,
    swipe: true,
    fade: true,
    easing: 'ease',

  }
  const { classes } = props
  const {


    title,
    subtitle,
    sliderImages,
  } = props

  return (
    <div
      className={classes.root}
    >
      <Slider {...settings} style={{ width: '100%', height: '100vh', margin: 0, padding: 0 }}>
        {sliderImages ? (
          sliderImages.map(image => {
            return <div style={{ height: '100%' }}>
              <Img
                style={{
                  height: '100vh',
                  width: '100%',
                  filter: 'saturate(80%) brightness(90%) contrast(110%)',
                }}
                alt={image.alt ? image.alt : 'Spanien'}
                fluid={image.fluid}

              />
            </div>
          })


        ) : null}


      </Slider>


      <div className={classes.container}>

        {/*<Link to={'/'}>*/}
        {/*<h1 className={classes.logo}>*/}
        {/*  {title}*/}
        {/*</h1>*/}
        {/*</Link>*/}

        <Section backgroundColor={'transparent'} paddingBottom={0} paddingTop={0} innerStyles={{ maxWidth: 680 }}>
          {title ?
            <div>
              <h1 className={classes.headerTitle}>
                <span style={{

                  fontWeight: 'bold',
                }}
                      className={classes.headerTitleT}
                >{title}</span>
                <br/>
                {subtitle}
              </h1>
            </div>
            :
            <div>
              <h4 className={classes.headerTitle}>
                {subtitle}
              </h4>

            </div>


          }

        </Section>
        {/*<WeatherWidget*/}
        {/*  link={data.datoCmsHome.wetterWidgetLink}*/}
        {/*/>*/}
      </div>
      {/*<div className={classes.scrollDown}>*/}


      {/*</div>*/}

      <div className={classes.scrollDown}>

        <ButtonScrollDown/>
      </div>
    </div>
  )

}


// PageHeader.propTypes = {
//   classes: PropTypes.object.isRequired,
//   className: PropTypes.string,
//   filter: PropTypes.bool,
//   children: PropTypes.node,
//   style: PropTypes.string,
//   image: PropTypes.string,
// }

export default withStyles(pageHeaderFullWithSliderStyle)(PageHeaderFullWithSlider)
