import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { maxContainerWidth, section } from 'assets/jss/material-kit-react.jsx'
import PropTypes from 'prop-types'

const styles = {
  section: {
    ...section,
    paddingTop: 120,
    paddingBottom: 120,

  },
  wrapper: {

    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',

    ...maxContainerWidth,
  },
}

function Section({ ...props }) {
  const { classes, children } = props
  return (
    <section className={classes.section} style={{backgroundColor:props.backgroundColor, paddingTop: props.paddingTop,
      paddingBottom: props.paddingBottom}}>
      <div className={classes.wrapper} style={props.innerStyles}>
        {children}
      </div>
    </section>
  )
}
Section.defaultProp = {
  backgroundColor: "#fff",
  paddingTop: 120,
  paddingBottom: 120,
  innerStyles: {}
}

Section.propTypes = {
  backgroundColor: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  innerStyles: PropTypes.object,
}

export default withStyles(styles)(Section)
