import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import PropTypes from 'prop-types'
import sectionHeaderStyle from './sectionHeaderStyle'


function SectionHeader({ ...props }) {
  const { classes } = props

  if(props.makeTitleH1){
    return (

      <div className={classes.root}>
        {props.header ? (<h1 className={classes.poppinsHeader}>{props.header}</h1>) : null}
        {props.header ? (<br/>) : null}

        {props.subHeader && props.insta ?
          (
            <a href={props.instalink}><h1 className={classes.H2}
                                          style={{ fontSize: props.subHeaderSize }}>{props.subHeader}</h1></a>

          ) : null}
        {props.subHeader && !props.insta ?
          (<h2 className={classes.H2}
               style={{ fontSize: props.subHeaderSize }}>{props.subHeader}</h2>

          ) : null}

      </div>
    )
  } else {
    return (
      <div className={classes.root}>
        {props.header ? (<h2 className={classes.poppinsHeader}>{props.header}</h2>) : null}
        {props.header ? (<br/>) : null}

        {props.subHeader && props.insta ?
          (
            <a href={props.instalink}><h2 className={classes.H2}
                                          style={{ fontSize: props.subHeaderSize }}>{props.subHeader}</h2></a>

          ) : null}
        {props.subHeader && !props.insta ?
          (<h2 className={classes.H2}
               style={{ fontSize: props.subHeaderSize }}>{props.subHeader}</h2>

          ) : null}

      </div>
    )
  }


}

SectionHeader.defaultProp = {
  subHeaderSize: 48,
  insta: false,
  makeTitleH1: false,
}

SectionHeader.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
}

export default withStyles(sectionHeaderStyle)(SectionHeader)
