import { logo } from 'assets/jss/material-kit-react.jsx'

const mainMenuStyle = theme => ({
  root: {
    width: '100%',
  },
  subWrapper: {
    position: 'absolute',
  },
  hideOnResponsive: {
    [theme.breakpoints.down('sm')]: {
      visible: 'hidden',
    },
  },
  mainMenuWrapper: {
    paddingTop: 36,
    paddingBottom: 36,
    margin: 0,
    listStyle: 'none',
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (max-width: 768px)': {
      display: 'none',
    },

  },
  icon: {
    fontSize: 32,

    textAlign: 'center',
  },
  menuItem: {
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    color: '#1a1a1a',
    fontSize: '22px',
    fontWeight: 500,
    '& a': {
      fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
      color: '#1a1a1a',
      fontSize: '22px',
      textDecoration: 'none',
      marginRight: '18px',
      borderBottom: '1px solid transparent',
      transition: 'border-bottom 0.4s ease',
      '&:hover': {
        borderBottom: '1px solid #1a1a1a',
      },
      '& li': {
        marginRight: '25px',
        boxSizing: 'border-box',
        borderImageWidth: 1,
      },
    },

  },

  menuItemLight: {

    color: '#fff',

    '& a': {

      color: '#fff',

      '&:hover': {
        borderBottom: '1px solid white',
      },

    },

  },
  aImmitation: {
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    color: '#1a1a1a',
    fontSize: '22px',
    textDecoration: 'none',
    marginRight: '18px',
    borderBottom: '1px solid transparent',
    transition: 'border-bottom 0.4s ease',
    '& li': {
      marginRight: '25px',
      boxSizing: 'border-box',
      borderImageWidth: 1,
    },
  },
  aImmitationLight: {
    color: 'white',
  },
  aImmitationIsOpen: {
    transition: 'border-bottom 0.4s ease', borderBottom: '1px solid #1a1a1a',
  },
  aImmitationIsOpenLight: {
    transition: 'border-bottom 0.4s ease', borderBottom: '1px solid white',
  },
  aImmitationIsClosed: {
    transition: 'border-bottom 0.4s ease', borderBottom: '1px solid transparent',
  },
  submenuWrapper: {
    flexDirection: 'column',
    listStyle: 'none',
    listStyleType: 'none',
    opacity: 0,
    transition: 'opacity 0.5s ease',


  },
  subMenuItem: {
    padding: '6px 0px',
    '& a': {
      '&:hover': {
        borderBottom: '1px solid transparent',
        opacity: 0.5,
      },
    },
    zIndex: 13,
  },
  logoWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '5%',
    paddingBottom: 80,
    '@media (max-width: 768px)': {
      paddingTop: 0,
    },
  },
  logo: {
    ...logo,
    margin: 0,
    padding: 0,
    zIndex: 12,

    fontSize: 36,


    color: '#fff',
    textAlign: 'center',
    transition: 'all .4s ease',


    width: 'auto',
  },

})

export default mainMenuStyle
