import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import Img from 'gatsby-image'

import recentBlogPostsStyle from './recentBlogPostsStyle.jsx'

import classNames from 'classnames'

import { graphql, Link, useStaticQuery } from 'gatsby'
import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../../components/Section/Section'
import SectionHeader from '../../components/SectionHeader/SectionHeader'


const RecentBlogPosts = props => {

  const data = useStaticQuery(graphql`
    query{
      allDatoCmsArtikel(
        limit:5
        filter:{
          meta:{status:{eq:"published"}  }}
      ){

        nodes{
          slug
          titel
          meta{
            updatedAt(formatString: "MM-DD-YYYY")
          }
          inhaltsausschnitt
          artikelbild{
            alt
            fluid(maxWidth:1800){
              src
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
    }
  `)

  const { classes } = props

  return (
    <Section backgroundColor={'#f4f4f4'}>
      <SectionHeader header={'Blog'} subHeader={'Über die Region'}/>

      <div className={classes.mainWrapper}>
        <div className={classes.firstWrapper}>
          {data.allDatoCmsArtikel.nodes.slice(0, 1).map(artikel => {
            return (
              <ReactRevealFadeIn>


                <Link to={'blog/' + artikel.slug}>


                  <Img
                    className={classNames(classes.bigImage, classes.imageDarkenOnHover)}
                    style={{ width: '100%', height: '100%' }}
                    fluid={artikel.artikelbild.fluid}
                    alt={artikel.artikelbild.alt}
                    backgroundColor={'#f2f2f2'}
                    objectFit="contain"
                    objectPosition="50% 50%"
                  />
                </Link>

                <Link to={'blog/' + artikel.slug}>
                  <div className={classes.bigArticleContent}>
                    <h4 className={classes.H2}>
                      {artikel.titel}
                    </h4>
                    <p className={classes.P}>
                      {artikel.inhaltsausschnitt ?
                        artikel.inhaltsausschnitt.length > 140 ? artikel.inhaltsausschnitt.slice(0, 140) + '...' : artikel.inhaltsausschnitt
                        : null}

                    </p>
                    <p className={classes.smallDate}> {artikel.meta.updatedAt}</p>
                  </div>
                </Link>

              </ReactRevealFadeIn>
            )
          })}


        </div>
        <div className={classes.secondWrapper}>
          {data.allDatoCmsArtikel.nodes.slice(1, 5).map(artikel => {
            return (
              <ReactRevealFadeIn>

                <div className={classes.smallAricleWrapper}>
                  <Link className={classes.linkClass} to={'blog/' + artikel.slug}>
                    <Img
                      className={classNames(classes.smallImage, classes.imageDarkenOnHover)}

                      fluid={artikel.artikelbild.fluid}
                      alt={artikel.artikelbild.alt}
                      backgroundColor={'#f2f2f2'}
                      objectFit="contain"
                      objectPosition="50% 50%"
                    />
                  </Link>

                  <div className={classes.smallArticleContent}>
                    <Link to={'blog/' + artikel.slug}>
                      <h4 className={classes.H3}>
                        {artikel.titel}
                      </h4>
                    </Link>
                    <Link to={'blog/' + artikel.slug} className={classes.dateMarginTop}>
                      <p className={classes.smallDate}>{artikel.meta.updatedAt}</p>
                    </Link>
                  </div>

                </div>


              </ReactRevealFadeIn>
            )
          })}


        </div>
      </div>


    </Section>
  )

}

export default withStyles(recentBlogPostsStyle)(RecentBlogPosts)
