/*!

 =========================================================
 * Material Kit React - v1.4.0 based on Material Kit - v2.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260

const backgroundColor = {
  backgroundColor: '#fff',
}

const transition = {
  // transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
}
// ##############################
// // // FONTS START
// #############################
const logo = {
  fontSize: 36,

  letterSpacing: -2,
  fontFamily: '\'Poppins\', sans-serif',
  fontWeight: 500,
  textTransform: 'none',

  '@media (max-width: 768px)': {
    fontSize: 24,
  },
}
const poppinsHeader = {
  margin: 0,
  padding: 0,
  fontFamily: '\'Poppins\', sans-serif',
  fontSize: 12,
  textTransform: 'uppercase',
  letterSpacing: 3,
  color: '#1a1a1a',

}
const P = {
  fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
  lineHeight: 1.3,
  fontSize: 22,
  margin: 0,
  padding: 0,
  color: '#1a1a1a',
  '@media (max-width: 768px)': {
    fontSize: 20,
  },
}
const H1 = {
  fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  lineHeight: 1.06,
  margin: 0,
  padding: 0,
  letterSpacing: -1,
  fontSize: 64,

  '@media (max-width: 1024px)': {
    fontSize: 48,
  },
  '@media (max-width: 768px)': {
    fontSize: 36,
  },
}
const H2 = {
  fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  lineHeight: 1.06,
  letterSpacing: -1,
  fontSize: 48,
  margin: 0,
  padding: 0,
  color: '#1a1a1a',
  '@media (max-width: 768px)': {
    fontSize: 32,
  },
}
const H3 = {
  fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  lineHeight: 1.06,
  letterSpacing: -1,
  fontSize: 36,
  fontStyle: 'normal',
  margin: 0,
  padding: 0,
  color: '#1a1a1a',
  '@media (max-width: 768px)': {
    fontSize: 28,
  },
}
const H4 = {
  fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  lineHeight: 1.06,
  letterSpacing: -1,
  fontSize: 36,
  fontStyle: 'normal',
  margin: 0,
  padding: 0,
  color: '#1a1a1a',
  '@media (max-width: 768px)': {
    fontSize: 28,
  },
}
const H5 = {
  fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  lineHeight: 1.06,
  letterSpacing: -1,
  fontSize: 36,
  fontStyle: 'normal',
  margin: 0,
  padding: 0,
  color: '#1a1a1a',
  '@media (max-width: 768px)': {
    fontSize: 28,
  },
}
const H6 = {
  fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  lineHeight: 1.06,
  letterSpacing: -1,
  fontSize: 36,
  fontStyle: 'normal',
  margin: 0,
  padding: 0,
  color: '#1a1a1a',
  '@media (max-width: 768px)': {
    fontSize: 28,
  },
}

// ##############################
// // // FONTS  END
// #############################
const imageDarkenOnHover = {
  transition: 'all 1s ease',
  '&:hover': {
    // transform: 'scale(1.1)',
    filter: 'brightness(70%)',
  },
}
const section = {
  paddingRight: '32px',
  paddingLeft: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  '@media (max-width: 576px)': {
    // maxWidth: "540px",
    paddingRight: '20px',
    paddingLeft: '20px',

  },
}

const conatinerFluid = {
  paddingRight: '34px',
  paddingLeft: '34px',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%',
}
const container = {
  // ...conatinerFluid,
  // '@media (min-width: 576px)': {
  //   // maxWidth: "540px",
  //   paddingRight: '20px',
  //   paddingLeft: '20px',
  // },
  // "@media (min-width: 768px)": {
  //   maxWidth: "720px",
  // },
  // "@media (min-width: 992px)": {
  //   maxWidth: "960px",
  // },
  '@media (min-width: 1200px)': {
    maxWidth: '1210px',
  },
}
const maxContainerWidth = { maxWidth: '1210px' }


const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
}

const card = {
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  margin: '25px 0',
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  borderRadius: '3px',
  color: 'rgba(0, 0, 0, 0.87)',
  background: '#fff',
}

const defaultFont = {
  fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
  fontWeight: '300',
  lineHeight: '1.5em',
}

// const primaryColor = '#9c27b0'
const primaryColor = '#FA4B50'
const warningColor = '#ff9800'
const dangerColor = '#f44336'
const successColor = '#4caf50'
const infoColor = '#00acc1'
const roseColor = '#e91e63'
const grayColor = '#999999'

const primaryBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(107,143,216, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(107,143,216, 0.2)',
}

// const primaryBoxShadow = {
//   boxShadow:
//     '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
// }
const infoBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)',
}
const successBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)',
}
const warningBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)',
}
const dangerBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)',
}
const roseBoxShadow = {
  boxShadow:
    '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)',
}

const warningCardHeader = {
  color: '#fff',
  background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
  ...warningBoxShadow,
}
const successCardHeader = {
  color: '#fff',
  background: 'linear-gradient(60deg, #66bb6a, #43a047)',
  ...successBoxShadow,
}
const dangerCardHeader = {
  color: '#fff',
  background: 'linear-gradient(60deg, #ef5350, #e53935)',
  ...dangerBoxShadow,
}
const infoCardHeader = {
  color: '#fff',
  background: 'linear-gradient(60deg, #26c6da, #00acc1)',
  ...infoBoxShadow,
}
const primaryCardHeader = {
  color: '#fff',
  background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
  ...primaryBoxShadow,
}
const roseCardHeader = {
  color: '#fff',
  background: 'linear-gradient(60deg, #ec407a, #d81b60)',
  ...roseBoxShadow,
}
const cardActions = {
  margin: '0 20px 10px',
  paddingTop: '10px',
  borderTop: '1px solid #eeeeee',
  height: 'auto',
  ...defaultFont,
}

const cardHeader = {
  margin: '-30px 15px 0',
  borderRadius: '3px',
  padding: '15px',
}

const defaultBoxShadow = {
  border: '0',
  borderRadius: '3px',
  boxShadow:
    '0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  padding: '10px 0',
  transition: 'all 150ms ease 0s',
}

const title = {
  color: '#3C4858',
  margin: '1.75rem 0 0.875rem',
  textDecoration: 'none',
  fontWeight: '900',
}


const cardTitle = {
  ...title,
  marginTop: '.625rem',
}

const cardLink = {
  '& + $cardLink': {
    marginLeft: '1.25rem',
  },
}

const cardSubtitle = {
  marginBottom: '0',
  marginTop: '-.375rem',
}

export {
  logo,
  poppinsHeader,
  P,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  //variables
  drawerWidth,
  backgroundColor,
  transition,
  container,
  conatinerFluid,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  title,
  cardTitle,
  cardLink,
  cardSubtitle,
  section,
  maxContainerWidth,
  imageDarkenOnHover,
}
