import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'


import threeArgumentsSectionSmallerWithHeaderStyle from './threeArgumentsSectionSmallerWithHeaderStyle'
import Img from 'gatsby-image'

import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../../components/Section/Section'
import SectionHeader from '../../components/SectionHeader/SectionHeader'

const ThreeArgumentsSectionSmallerWithHeader = (props) => {

  const { classes, threeArguments, title,subtitle } = props

  const imgType = classNames({
    [classes.imgClass]: true,
    [classes.imageDarkenOnHover]: true,
    [classes.tall]: props.tall,
  })
  return (
    <Section backgroundColor={'#f2f2ee'} paddingBottom={40} paddingTop={160}>

      <SectionHeader header={title} subHeader={subtitle}/>

        <div className={classes.wrapper}>

          {threeArguments.map(argument => {
            return (
              <ReactRevealFadeIn>
              <div className={classes.itemWrapper}>
                <div className={classes.imgWrapper}>
                  <Img
                    className={imgType}

                    fluid={argument.image.fluid}
                    alt={argument.image.alt}
                    backgroundColor={'#f2f2f2'}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>

                <div className={classes.itemGrid}>
                  <h3 className={classes.linkHeader}>
                    {argument.title}
                  </h3>

                  <p className={classes.linkSubtitle}>
                    {argument.subtitle}
                  </p>

                </div>

              </div>
              </ReactRevealFadeIn>

            )
          })}


        </div>



    </Section>
  )

}
ThreeArgumentsSectionSmallerWithHeader.defaultProp = {
  tall: false,
}

export default withStyles(threeArgumentsSectionSmallerWithHeaderStyle)(ThreeArgumentsSectionSmallerWithHeader)
