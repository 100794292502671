import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Section from '../../components/Section/Section'

import { Link } from 'gatsby'
import bannerImgBgStyle from './bannerImgBgStyle'
import BackgroundImage from 'gatsby-background-image'

const BannerWithImg = props => {
  const { classes, image, title, link, text, buttonTitle } = props
  return (
    <div className={classes.root}>

      <BackgroundImage
        className={classes.imageStyles}
        fluid={image.fluid}
      >
        <Section backgroundColor={'rgba(255,239,239,0.9)'} paddingBottom={0} paddingTop={0}>

          <div className={classes.wrapper}>
            <div className={classes.textWrapper}>
              <h3 className={classes.poppinsHeader}>— {title}</h3>
              <h2 className={classes.H1}>
                {text}
              </h2>
            </div>

            <Link className={classes.buttonMainWrapper} to={'/' + link}>
              <div className={classes.buttonWrapper}>
                <p className={classes.P}>
                  {buttonTitle}
                </p>
              </div>
            </Link>
          </div>

        </Section>
      </BackgroundImage>
    </div>

  )
}

BannerWithImg.defaultProp = {
  text: 'Überwintern',

}
//
BannerWithImg.propTypes = {}

export default withStyles(bannerImgBgStyle)(BannerWithImg)