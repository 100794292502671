const fixedMenuStyle = {
  root: {
    height: 100,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 1000,
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  isVisible: {},
  wrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {


    paddingLeft: 40,
    fontSize: 25,
    color: '#1a1a1a',
    textAlign: 'center',
    transition: 'all .4s ease',
    fontFamily: '\'Poppins\', sans-serif',
    fontWeight: 500,
    textTransform: 'none',

  },
}

export default fixedMenuStyle
