import { poppinsHeader } from 'assets/jss/material-kit-react.jsx'

const weatherWidgetStyle = {
  root: {
    // // top: '45%',
    // marginTop: 20,
    // display: 'flex',
    // width: '100%',
    // justifyContent: 'center',
    // alignItems: 'center',
    // flexDirection: 'row',
    // fontFamily: '"Cormorant Garamond", sans-serif',
    // color: '#fff',
    // padding: 15,
    // lineHeight: 1.45,
    // zIndex: 16,
    // '@media (max-width: 330px)': {
    //   display: 'none',
    // },
    position:"absolute",
    zIndex:999,
    backgroundColor: "khaki", height:400, width:400
  },
  wrapper: {
    // border: '1px solid white',
    borderRadius: 99,
    paddingTop: 8,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#31BDFF',
    opacity: 1 ,
    transition: 'all 1s ease',
    '&:hover': {
      opacity: 0.8,
    },
  },
  font: {
    ...poppinsHeader,
    margin: 0,
    padding: 0,
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 12,
    '@media (max-width: 768px)': {
      // display: 'none',
      fontSize: 10,
    },
  },
  iconWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 15,
  },
  icon: {
    color: '#F8CE46',

    fontSize: 22,
    '@media (max-width: 768px)': {
      // display: 'none',
      fontSize: 18,
    },
  },
}

export default weatherWidgetStyle
