import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'


import Footer from 'components/Footer/Footer.jsx'
import { graphql, useStaticQuery } from 'gatsby'

import Header from '../../components/Header/Header'
import PageHeaderFull from '../../components/PageHeaderFull/PageHeaderFull'
import homeStyle from './homeStyle'
import FerienhaeuserLinksSection from '../../elements/FerienhaeuserLinksSection/FerienhaeuserLinksSection'
import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
import InstagramSection from '../../components/InstagramSection/InstagramSection'
import SpainHolidaysCta from '../../elements/SpainholidaysCta/SpainHolidaysCta'
import Impressionen from '../../elements/Impressionen/Impressionen'

import BannerWithImg from '../../elements/BannerWithImg/BannerWithImg'

// Sections for this page
import Slider from "react-slick";
import PageHeaderFullWithSlider from '../../components/PageHeaderFullWithSlider/PageHeaderFullWithSlider'
import Section from '../../components/Section/Section'
import Img from 'gatsby-image'
import ImpressionenWithVideo from '../../elements/ImpressionenWithVideo/ImpressionenWithVideo'
import ThreeArgumentsSection from '../../elements/threeArgumentsSection/threeArgumentsSection'
import ThreeArgumentsSectionSmallerWithHeader
  from '../../elements/threeArgumentsSectionSmallerWithHeader/threeArgumentsSectionSmallerWithHeader'
import BannerAlternative from '../../elements/BannerAlternative/BannerAlternative'

const Home = props => {

  const data = useStaticQuery(graphql`
    query {
      datoCmsHome{
        untertitel
          imageSlider{
              alt
              fluid(maxWidth:1800){

                  ...GatsbyDatoCmsFluid_noBase64
              }
          }
      
        seitenlinks{
          bild{
            alt
            fluid(maxWidth:1800){
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          titel
          untertitel
          link{
            ...on DatoCmsUeberMich{
              slug
            }
            ...on DatoCmsUeberwintern{
              slug
            }
            ...on DatoCmsBlog{
              slug
            }
            ...on DatoCmsFerienhausGreen{
              slug
            }
            ...on DatoCmsFerienhausMogariza{
              slug
            }
            ...on DatoCmsFerienhausConil{
              slug
            }
          }
        }
        impressions{
          title
          subtitle
            videoYoutubeLink
            videoAutoplay
          images{
            alt
            fluid(maxWidth:900){
              src
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
          arguments{
              title
              subtitle
              image{
                  fluid(maxWidth:500){
                      src
                      ...GatsbyDatoCmsFluid_noBase64
                  }
              }
          }
        bannerWithBgImage{
          title
          text
          image{
            alt
            fluid(maxWidth:1800){
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          buttonTitle
          buttonLink{
            ...on DatoCmsUeberMich{
              slug
            }
            ...on DatoCmsUeberwintern{
              slug
            }
            ...on DatoCmsFerienhausGreen{
              slug
            }
            ...on DatoCmsFerienhausMogariza{
              slug
            }
            ...on DatoCmsFerienhausConil{
              slug
            }
          }
        }
        spainholidayBanner{
          title
          subtitle
          buttonLink
          buttonTitle
        }
      }
    }
  `)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const { classes } = props

  return (
    <div className={classes.backgroundColor}>

      <Header
        SEOtitle={"Home"}
        SEOdescription={"Willkommen in Andalusien"}
        logoAsH1
        absolute
        light
      />
      <PageHeaderFullWithSlider

        sliderImages={data.datoCmsHome.imageSlider}
        // title={data.datoCmsHome.titel}
        subtitle={data.datoCmsHome.untertitel}
      />


      <ThreeArgumentsSectionSmallerWithHeader

        title ={"Ein sonniges"}
        subtitle ={"Willkommen"}
        threeArguments={data.datoCmsHome.arguments}
      />


      <ImpressionenWithVideo
        backgroundColor={'#fff'}
        title={data.datoCmsHome.impressions[0].title}
        videoAutoplay={data.datoCmsHome.impressions[0].videoAutoplay}
        subtitle={data.datoCmsHome.impressions[0].subtitle}
        images={data.datoCmsHome.impressions[0].images}
        link={data.datoCmsHome.impressions[0].videoYoutubeLink}

      />
      <FerienhaeuserLinksSection seitenlinks={data.datoCmsHome.seitenlinks}/>

      <BannerAlternative
        title={data.datoCmsHome.bannerWithBgImage[0].title}
        text={data.datoCmsHome.bannerWithBgImage[0].text}
        image={data.datoCmsHome.bannerWithBgImage[0].image}
        buttonTitle={data.datoCmsHome.bannerWithBgImage[0].buttonTitle}
        link={data.datoCmsHome.bannerWithBgImage[0].buttonLink.slug}
      />
      {/*<BlogSection/>*/}
      <InstagramSection/>
      <SpainHolidaysCta
        title={data.datoCmsHome.spainholidayBanner[0].title}
        subtitle={data.datoCmsHome.spainholidayBanner[0].subtitle}
        buttonTitle={data.datoCmsHome.spainholidayBanner[0].buttonTitle}
        buttonLink={data.datoCmsHome.spainholidayBanner[0].buttonLink}
      />
      <Footer/>
    </div>
  )

}

export default withStyles(homeStyle)(Home)
