import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import weatherWidgetStyle from './weatherWidgetStyle'
import Helmet from 'react-helmet'



class WeatherWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      wdata: null,
    }

  }

  componentDidMount() {
    fetch('http://api.openweathermap.org/data/2.5/weather?id=2519513&APPID=b2332a3cdfb2a667f480d8bbc0dffb15')
      .then(res => res.json())
      .then((data) => {
        this.setState({ wdata: data })
        // console.log(data)
        // console.log(data.name)
      })
      .catch('weather widget ERROR ', console.log)
    setInterval(() => {
      fetch('http://api.openweathermap.org/data/2.5/weather?id=2519513&APPID=b2332a3cdfb2a667f480d8bbc0dffb15')
        .then(res => res.json())
        .then((data) => {
          this.setState({ wdata: data })
          // console.log(data)
          // console.log(data.name)
        })
        .catch('weather widget ERROR ', console.log)
    }, 30000)

  }

  render() {
    const { classes, link } = this.props

    return (


      <div className={classes.root}>


        {/*/!*<div id="cont_6fbd38766f66cd08ed06e290374719c6">*!/*/}
        {/*/!*  <Helmet>*!/*/}

        {/*/!*      <script type="text/javascript" async*!/*/}
        {/*/!*              src="https://www.daswetter.com/wid_loader/6fbd38766f66cd08ed06e290374719c6"></script>*!/*/}

        {/*/!*  </Helmet>*!/*/}
        {/*/!*</div>*!/*/}


        {/*<a href={link} rel="noopener noreferrer"*/}
        {/*   target="_blank">*/}

        {/*  <div className={classes.wrapper}>*/}


        {/*    <p className={classes.font}>*/}
        {/*      Chiclana de la Frontera*/}
        {/*    </p>*/}
        {/*    {this.state.wdata ?*/}
        {/*      <ReactRevealFadeIn>*/}
        {/*        /!*<img src={'http://openweathermap.org/img/wn/' + this.state.wdata.weather[0].icon + '@2x.png'}*!/*/}
        {/*        /!*     alt="Smiley face" height="40" width="40" style={{*!/*/}
        {/*        /!*  filter: ' brightness(500%)',*!/*/}
        {/*        /!*}}/>*!/*/}
        {/*        <div className={classes.iconWrapper}>*/}
        {/*          <DeleteOutlinedIcon className={classes.icon}/>*/}
        {/*        </div>*/}
        {/*        <p className={classes.font}>*/}
        {/*          {this.state.wdata ? (Math.ceil(this.state.wdata.main.temp - 273.15)) : null} °C*/}
        {/*        </p>*/}
        {/*      </ReactRevealFadeIn>*/}
        {/*      :*/}
        {/*      <ReactRevealFadeIn>*/}
        {/*        <div className={classes.iconWrapper}>*/}
        {/*          <DeleteOutlinedIcon className={classes.icon}/>*/}
        {/*        </div>*/}
        {/*        <p className={classes.font}>*/}
        {/*          25 °C*/}
        {/*        </p>*/}

        {/*      </ReactRevealFadeIn>*/}

        {/*    }*/}


        {/*  </div>*/}
        {/*</a>*/}

      </div>


    )
  }

}


export default withStyles(weatherWidgetStyle)(WeatherWidget)
