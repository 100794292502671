import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Img from 'gatsby-image'

import impressionenStyle from './impressionenStyle.jsx'

import ReactRevealFadeIn from '../../components/ReactRevealFadeIn/ReactRevealFadeIn'
import Section from '../../components/Section/Section'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import PropTypes from 'prop-types'


const Impressionen = props => {

  const { classes } = props

  return (

    <Section paddingBottom={40} backgroundColor={props.backgroundColor ? props.backgroundColor : '#f4f4f4'}>
      <SectionHeader header={props.title}
                     subHeader={props.subtitle}/>

      <ReactRevealFadeIn>
        <div className={classes.wrapper}>
          {props.images.slice(0, 2).map(image => {
            return (
              <div className={classes.itemWrapper}>


                <Img
                  // className={imageClasses}
                  style={{ height: '100%', width: '100%' }}
                  fluid={image.fluid}
                  alt={image.alt}
                  backgroundColor={'#f2f2f2'}
                  objectFit="contain"
                  objectPosition="50% 50%"
                />


              </div>
            )
          })}

        </div>
      </ReactRevealFadeIn>
      <ReactRevealFadeIn>
        <div className={classes.wrapper}>
          {props.images.slice(2, 4).map(image => {
            return (
              <div className={classes.itemWrapper}>


                <Img
                  className={classes.imageClasses}
                  fluid={image.fluid}
                  alt={image.alt}
                  backgroundColor={'#f2f2f2'}
                  objectFit="contain"
                  objectPosition="50% 50%"
                />


              </div>
            )
          })}

        </div>
      </ReactRevealFadeIn>
    </Section>
  )

}
Impressionen.defaultProp = {
  backgroundColor: '#f4f4f4',
}

Impressionen.propTypes = {
  backgroundColor: PropTypes.string,
}
export default withStyles(impressionenStyle)(Impressionen)
